import React, { useState, useEffect, useRef } from 'react';
import ChatMessages from './components/ChatMessages';
import ChatInput from './components/ChatInput';
import LoadingSpinner from './components/LoadingSpinner';
import './index.css';
import './App.css';
import PromptWithImg from './components/promptWithImg';

const App = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstMessageSent, setIsFirstMessageSent] = useState(false);
  const chatMessagesRef = useRef(null);

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async (userMessage) => {
    if (!userMessage.trim()) return;

    const userMsg = { sender: 'user', text: userMessage };
    setMessages((prev) => [...prev, userMsg]);
    setIsLoading(true);
    setIsFirstMessageSent(true);

    try {
      const startTime = Date.now();
      const botReply = await getBotReply(userMessage);
      const endTime = Date.now();
      const elapsedTime = (endTime - startTime) / 1000; // Convert to seconds
      const botMsg = { sender: 'bot', text: botReply, isYaml: true };
      setMessages((prev) => [...prev, botMsg]);
      console.log(`Request took ${elapsedTime} seconds`);
    } catch (error) {
      console.error('Error fetching bot response:', error);
      const errorMsg = {
        sender: 'bot',
        text: `Sorry, something went wrong. Please try again. ${error}`,
      };
      setMessages((prev) => [...prev, errorMsg]);
    } finally {
      setIsLoading(false);
    }
  };

  const getBotReply = async (message) => {
    const url = 'https://pdechatqa.tdd-np.o360.cloud/generate';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ prompt: message });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetch error:', error.message);
      throw new Error(`Fetch error: ${error.message}`);
    }
  };

  return (
    <div className="app">
      <div className="top-bar">Optum</div>
      <div className="app">
        <div className="chat-header">Smart GH Assistant</div>
        <div className={`chat-container ${isFirstMessageSent ? 'bottom' : 'center'}`} ref={chatMessagesRef}>
          <ChatMessages messages={messages} isLoading={isLoading} />
          {!isFirstMessageSent && <PromptWithImg />}
        </div>
        <div className={`chat-input-container ${isFirstMessageSent ? 'bottom' : 'center'}`}>
          <ChatInput onSendMessage={handleSendMessage} isLoading={isLoading} />
          {isLoading && <LoadingSpinner />}
        </div>
        <div className="bottom-bar">
          For any issues, enter a Service Now ticket as described <a href="https://confluence.optum.com/display/FICSDO/Tech+&+Data+DevOps+Enablement" className="white-link">here</a>.
        </div>
      </div>
    </div>
  );
};

export default App;
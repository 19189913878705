import React from 'react';
import './promptWithImg.css';

const PromptBox = ({ text }) => {
  return (
    <div className="prompt-box">
      {text}
    </div>
  );
};

const PromptWithImg = () => {
  return (
    <div className="container">
      <div className="top-section">
        <div className="msgimg-container">
            <img className="msgimg" src="/images/sample1.jpg" alt="Sample" />
        </div>
      </div>
      <div className="bottom-section">
        <PromptBox text="Sample Prompt 1" />
        <PromptBox text="Sample Prompt 2" />
        <PromptBox text="Sample Prompt 3" />
      </div>
    </div>
  );
};

export default PromptWithImg;
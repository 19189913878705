import React, { useState, forwardRef } from 'react';
import './ChatMessages.css';

const ChatMessage = ({ sender, text, isYaml }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setTooltipVisible(true);
      setTimeout(() => setTooltipVisible(false), 2000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div className={`chat-message ${sender}`}>
      {/* <img
        src={sender === 'user' ? 'https://i.pravatar.cc/150?img=3' : 'https://robohash.org/bot.png?size=150x150'}
        alt={`${sender} avatar`}
        className="avatar"
      /> */}
      <pre>{text}</pre>
      {isYaml && (
        <button className="copy-button" onClick={handleCopy}>
          <img src="https://img.icons8.com/ios-glyphs/30/000000/copy.png" alt="Copy" />
          {tooltipVisible && <span className="tooltip">Copied!</span>}
        </button>
      )}
    </div>
  );
};

const ChatMessages = forwardRef(({ messages = [], isLoading }, ref) => {
  return (
    <div className="chat-messages" ref={ref}>
      {messages.map((msg, index) => (
        <ChatMessage key={index} sender={msg.sender} text={msg.text} isYaml={msg.isYaml} />
      ))}
      {isLoading && <div className="loading-indicator">Generating Workflow...</div>}
    </div>
  );
});

export default ChatMessages;